import React, { cloneElement } from 'react'

import Iframe from 'components/shared/Iframe'

import css from './styles.module.scss'

const Html = ({ element }) => {
  switch (element.type) {
    case 'div':
      return <div className={css.iframe}>{element}</div>
    case 'iframe':
      return (
        <Iframe className={css.iframe}>
          {cloneElement(element, {
            // fix cookie-bot blocking
            // https://support.cookiebot.com/hc/en-us/articles/360003790854-Iframe-cookie-consent-with-YouTube-example
            'data-cookieconsent': 'marketing',
          })}
        </Iframe>
      )
    default:
      return element
  }
}

export default Html
